<template>
	<div class="animated fadeIn p-3">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						v-if="admin.includes($user.details().type)"
						class="col-sm">
						<search
							v-model="filters.id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor'}"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="startDate"
								class="label">{{ translate('start_date') }}</label>
							<v-date-picker
								id="startDate"
								v-model="startDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="startDate"
								show-caps />
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="endDate"
								class="label">{{ translate('end_date') }}</label>
							<v-date-picker
								id="endate"
								v-model="endDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="endDate"
								show-caps />
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th>
							{{ translate('period') }}
						</th>
						<th class="text-center">
							{{ translate('new_distributors') }}
						</th>
						<th class="text-center">
							{{ translate('new_customers') }}
						</th>
						<th class="text-right">
							{{ translate('sponsored_orders') }}
						</th>
						<th class="text-right">
							{{ translate('customer_orders') }}
						</th>
						<th class="text-right">
							{{ translate('total') }}
						</th>
						<th
							v-if="!isJns()"
							class="text-right">
							{{ translate('trip_points') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="text-left text-capitalize">
							{{ periodInfo(item.attributes.group, responseType) }}
						</td>
						<td class="align-middle text-center">
							{{ item.attributes.new_distributors }}
						</td>
						<td class="align-middle text-center">
							{{ item.attributes.new_customers }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.sponsored_orders }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.customer_orders }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.total_bvs }}
						</td>
						<td
							v-if="!isJns()"
							class="align-middle text-right">
							{{ item.attributes.new_pbv }}
						</td>
					</tr>
					<tr class="text-right">
						<td>
							<b />
						</td>
						<td class="text-center">
							<b>{{ totals.new_distributors }}</b>
						</td>
						<td class="text-center">
							<b>{{ totals.new_customers }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.sponsored_orders }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.customer_orders }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.total_bvs }}</b>
						</td>
						<td
							v-if="!isJns()"
							class="text-right">
							<b>{{ totals.new_pbv }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>
import {
	PersonalBusinessVolume as PBV, Grids,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import {
	PERIOD_FILTERS as dateRanges,
	ALL_TIME_PERIOD_FILTERS as allTimeDateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	DATE_START_FILTER as dateStartFilter,
	DATE_END_FILTER as dateEndFilter,
	MD_FORMAT as mdFormat,
	MY_FORMAT as myFormat,
	MDY_FORMAT as mdyFormat,
	YEAR_FORMAT as yFormat,
	custom,
} from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import NewEnrollmentReport from '@/util/Report';
import { SEARCH_USERS } from '@/config/endpoint';
import { admin } from '@/settings/Roles';
import { SHOW_TRIP_POINTS_COUNTRIES } from '@/settings/Country';
import WindowSizes from '@/mixins/WindowSizes';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'NewEnrollment',
	messages: [PBV, Grids],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, WindowSizes],
	data() {
		return {
			dateRanges,
			allTimeDateRanges,
			calendarConfig,
			mdFormat,
			myFormat,
			mdyFormat,
			yFormat,
			custom,
			endpoint,
			rangeStart: '',
			rangeEnd: '',
			admin,
			newEnrollmentReport: new NewEnrollmentReport(),
			tripPointsCountries: SHOW_TRIP_POINTS_COUNTRIES,
		};
	},
	computed: {
		loading() {
			return !!this.newEnrollmentReport.data.loading;
		},
		data() {
			try {
				const { data } = this.newEnrollmentReport.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		responseType() {
			try {
				return this.newEnrollmentReport.data.response.data.meta.type;
			} catch (error) {
				return 'custom';
			}
		},
		totals() {
			try {
				const { totals } = this.newEnrollmentReport.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
		datePeriod() {
			return this.getDatePeriod();
		},
	},
	mounted() {
		let { id } = this.$user.details();
		if (this.filters.id) {
			id = this.filters.id;
		}
		this.newEnrollmentReport.getNewEnrollment(id).then(() => {
			const { start, end } = this.newEnrollmentReport.data.response.data.meta.period_dates;
			this.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
		this.datesConfig();
	},
	methods: {
		datesConfig() {
			this.rangeStart = '';
			this.rangeEnd = '';
			delete this.dateRanges.all_time;
			delete this.dateRanges.custom;
			this.dateRanges = { ...this.dateRanges, ...this.allTimeDateRanges };
			this.dateRanges.custom = this.custom;
			const { query } = this.$route;
			const dateKeys = Object.keys(query);
			if (typeof this.dateRanges[query.dateRange] !== 'undefined') {
				this.dateRange = query.dateRange;
			} else if (typeof query.dateRange !== 'undefined' && !Object.values(this.dateRanges).includes(query.dateRange)) {
				this.dateRange = this.dateRanges.this_month;
				query.dateRange = this.dateRanges.this_month;
			} else if (dateKeys.includes(dateStartFilter) || dateKeys.includes(dateEndFilter)) {
				this.dateRange = this.dateRanges.custom;
			} else if (typeof query.dateRange === 'undefined') {
				this.dateRange = this.dateRanges.this_month;
			}
			return null;
		},
		periodInfo(group, type) {
			try {
				if (type === 'custom') {
					return this.translate(group);
				}
				if (type === 'months') {
					return this.$moment(group.date).format(myFormat);
				}
				if (type === 'days') {
					return this.$moment(group.date).format(mdFormat);
				}
				if (type === 'weeks') {
					return `${this.$moment(group.start.date).format(mdFormat)} - ${this.$moment(group.end.date).format(mdFormat)}`;
				}
				if (type === 'years') {
					return this.$moment(group.date).format(yFormat);
				}
				if (type === 'weekly_year') {
					return `${this.$moment(group.start.date).format(mdyFormat)} - ${this.$moment(group.end.date).format(mdyFormat)}`;
				}
			} catch (error) {
				return '';
			}
			return '';
		},
		getDataFiltered() {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			let { id } = this.$user.details();
			if (this.filters.id) {
				id = this.filters.id;
			}
			this.newEnrollmentReport.getNewEnrollment(id, options);
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
};
</script>
